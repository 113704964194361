import React, { useState, useEffect } from "react";
// tools
import { io } from "socket.io-client";
import { A2VOTE_API } from "utils/config";


const IoTest = () => {

  const [value, setValue] = useState('');
  const [messages, setMessages] = useState([]);
  const socket = io(
    //reconnection: false,
    //allowEIO3: true,
    //credentials: true // defaults to true
 `${A2VOTE_API}`)

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected!');
    });
    socket.on('onMessage', (newMessage) => {
      console.log('onMessage event received!');
      console.log(newMessage);
      setMessages(messages => [...messages, newMessage]);
    });
    return () => {
      console.log('Unregistering Events...');
      socket.off('connect');
      socket.off('onMessage');
    };
  }, []);

  const onSubmit = () => {
    socket.emit('newMessage', value);
    //setMessages(messages => [...messages, value]);
    console.log(messages)
    //setValue('');
  };

  return (
    <div>
      <div>
        <h1>Websocket Component</h1>
        <div>
          {messages.length === 0 ? (
            <div>No Messages</div>
          ) : (
            <div>
              {messages.map((msg, index) => (
                <div key={index}>
                  <p>{msg}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div>
          <input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <button onClick={onSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default IoTest;
